.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  padding: 25px;
  align-self: flex-end;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 25px;
}

.nameText {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 10px;
}

.titleText {
  font-family: Recursive, sans-serif;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #6F7373;
}

.connectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.connectIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.connectIcon {
  width: 30px;
  height: 30px;
}

.statementsWrapper {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statementsContainer {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.statementsScrollerContainer {
  position: absolute;
  top: 0px;
  transform: translateY(0px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statementContainer {
  display: flex;
  align-items: center;
  height: 40px;
  user-select: none;
}

.floatingInspectContainer {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.inspectorContainer {
  position: absolute;
  top: 0px;
  transform: translateY(0px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,255,0, 0.288);
}

.inspectItem {
  width: 100%;
  height: 40px;
  border-bottom: 1px dotted #333;
}

.inspectItem:first-child {
  border-top: 1px dotted #333;
}

.cta {
  font-family: Roboto, sans-serif;
  color: #379683;
  font-weight: 300;
  margin-bottom: 25px;
}

.cta a {
  color: #379683;
  text-decoration: none;
  font-weight: 500;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .nameContainer {
      align-items: flex-start;
    }
    .nameText {
      font-size: 24px;
      margin-bottom: 5px;
    }
    .cta {
      width: 80%;
      text-align: center;
      line-height: 24px;
    }
}