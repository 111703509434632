@import url(https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;500&display=swap);
body {
  font-family: 'Recursive', sans-serif;
  color: #0F1111;
  font-size: 16px;
  line-height: 24px;
}

* {
  box-sizing: border-box;
}
.styles_container__1gq13 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_headerContainer__3nZJK {
  display: flex;
  flex-direction: row;
  padding: 25px;
  align-self: flex-end;
}

.styles_nameContainer__1JSrV {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 25px;
}

.styles_nameText__3J5aL {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 10px;
}

.styles_titleText__29it3 {
  font-family: Recursive, sans-serif;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #6F7373;
}

.styles_connectContainer__1TygS {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles_connectIconContainer__3OOqd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.styles_connectIcon__94heu {
  width: 30px;
  height: 30px;
}

.styles_statementsWrapper__1bcv4 {
  width: 100%;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_statementsContainer__1wB4L {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.styles_statementsScrollerContainer__3Hy4s {
  position: absolute;
  top: 0px;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_statementContainer__whZAk {
  display: flex;
  align-items: center;
  height: 40px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.styles_floatingInspectContainer__lHkLa {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.styles_inspectorContainer__1AhRd {
  position: absolute;
  top: 0px;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,255,0, 0.288);
}

.styles_inspectItem__1yafw {
  width: 100%;
  height: 40px;
  border-bottom: 1px dotted #333;
}

.styles_inspectItem__1yafw:first-child {
  border-top: 1px dotted #333;
}

.styles_cta__29nI5 {
  font-family: Roboto, sans-serif;
  color: #379683;
  font-weight: 300;
  margin-bottom: 25px;
}

.styles_cta__29nI5 a {
  color: #379683;
  text-decoration: none;
  font-weight: 500;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .styles_nameContainer__1JSrV {
      align-items: flex-start;
    }
    .styles_nameText__3J5aL {
      font-size: 24px;
      margin-bottom: 5px;
    }
    .styles_cta__29nI5 {
      width: 80%;
      text-align: center;
      line-height: 24px;
    }
}
