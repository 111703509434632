@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;500&display=swap');

body {
  font-family: 'Recursive', sans-serif;
  color: #0F1111;
  font-size: 16px;
  line-height: 24px;
}

* {
  box-sizing: border-box;
}